<template>
  <v-container fluid class="cbg">
    <!-- <div class="d-flex flex-wrap justify-space-around grid"> -->
    <div class="grid">
      <div v-for="(item, index) in items" :key="index">
        <v-card
          dark
          class="gra1 bord mb-3"
          elevation="0"
          :to="`/checkroom/${item.class}`"
        >
          <div class="d-flex flex-no-wrap justify-start">
            <v-btn
              v-if="item.confirm == '1'"
              class="mt-7 mr-n4"
              elevation="0"
              fab
              top
              right
              absolute
              icon
            >
              <v-icon x-large>mdi-check-bold</v-icon>
            </v-btn>
            <v-avatar class="ma-0 pa-0" size="100" tile>
              <v-icon size="100" class="pa-0 ma-0">{{ item.icon }}</v-icon>
            </v-avatar>
            <div>
              <v-card-title class="text-h5 mt-n2">
                {{ item.class
                }}<v-icon class="mx-2">mdi-arrow-right-bold</v-icon
                >{{ item.next }}
                <div class="end-text ml-2" v-if="item.next == '10'">
                  (จบหลักสูตร)
                </div>
              </v-card-title>
              <v-card-subtitle
                v-text="item.artist"
                class="mb-n2"
              ></v-card-subtitle>
              <v-card-text class="mb-n2">
                <div>
                  ทั้งหมด : {{ item.amount }} ท่าน | เลือก :
                  {{ item.amountnext }} ท่าน
                </div>
              </v-card-text>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
const token = localStorage.getItem("token");
axios.defaults.headers.common = {
  Authorization: `Bearer ${token}`,
  Accept: "application/json",
};

export default {
  components: {},
  data: () => ({
    val: 0,
    items: [
      {
        icon: "mdi-numeric-2-box",
        class: "2",
        next: "3",
        artist: "หลักสูตร 2 เลื่อนสู่หลักสูตร 3",
        amount: 0,
        amountnext: 0,
        confirm: "",
      },
      {
        icon: "mdi-numeric-3-box",
        class: "3",
        next: "4",
        artist: "หลักสูตร 3 เลื่อนสู่หลักสูตร 4",
        amount: 0,
        amountnext: 0,
        confirm: "",
      },
      {
        icon: "mdi-numeric-4-box",
        class: "4",
        next: "พ.1",
        artist: "หลักสูตร 4 เลื่อนสู่หลักสูตร พ.1",
        amount: 0,
        amountnext: 0,
        confirm: "",
      },
      {
        icon: "mdi-numeric-พ.1-box",
        class: "พ.1",
        next: "พ.2",
        artist: "หลักสูตร พ.2 เลื่อนสู่หลักสูตร พ.3",
        amount: 0,
        amountnext: 0,
        confirm: "",
      },
      {
        icon: "mdi-numeric-6-box",
        class: "6",
        next: "7",
        artist: "หลักสูตร 6 เลื่อนสู่หลักสูตร 7",
        amount: 0,
        amountnext: 0,
        confirm: "",
      },
      {
        icon: "mdi-numeric-7-box",
        class: "7",
        next: "7.10",
        artist: "หลักสูตร 7 เลื่อนสู่หลักสูตร 7.10",
        amount: 0,
        amountnext: 0,
        confirm: "",
      },
      {
        icon: "mdi-numeric-7-box",
        class: "7.10",
        next: "8",
        artist: "หลักสูตร 7.10 เลื่อนสู่หลักสูตร 8",
        amount: 0,
        amountnext: 0,
        confirm: "",
      },
      {
        icon: "mdi-numeric-8-box",
        class: "8",
        next: "9",
        artist: "หลักสูตร 8 เลื่อนสู่หลักสูตร 9",
        amount: 0,
        amountnext: 0,
        confirm: "",
      },
      {
        icon: "mdi-numeric-9-box",
        class: "9",
        next: "10",
        artist: "หลักสูตร 9 เลื่อนสู่หลักสูตร 10",
        amount: 0,
        amountnext: 0,
        confirm: "",
      },
    ],
  }),
  methods: {
    async fetch_count() {
      let { data } = await axios.get(`${this.apiurl}/api/countpeople`, {
        params: {},
      });
      // console.log(data);
      // this.items[index].count = data;
      // await data.forEach(
      //   () =>
      //     // console.log(this.items.findIndex((it) => it.class == da.class))
      //     (this.items[0].amount = "55")
      //       // this.items.findIndex((it) => it.class == da.class)
      // );
      for (let i = 0; i < data.length; i++) {
        let index = this.items.findIndex((it) => it.class == data[i].class);
        if (index != -1) {
          this.items[index].amount = data[i].amount;
        }
        // console.log(index);
      }
    },

    async fetch_count_next() {
      let { data } = await axios.get(`${this.apiurl}/api/countpeoplenext`, {
        params: {},
      });
      for (let i = 0; i < data.length; i++) {
        let index = this.items.findIndex((it) => it.class == data[i].class);
        if (index != -1) {
          this.items[index].amountnext = data[i].amount;
        }
        // console.log(index);
      }
    },

    async fetch_roomconfirm() {
      let { data } = await axios.get(`${this.apiurl}/api/roomconfirm`, {
        params: {},
      });
      for (let i = await 0; i < this.items.length; i++) {
        this.items[i].confirm = await data[i];
      }
    },
  },
  computed: {
    apiurl() {
      return this.$store.state.apiurl;
    },
  },
  mounted() {
    this.fetch_roomconfirm();
    this.fetch_count();
    this.fetch_count_next();
  },
};
</script>

<style>
.logosvg {
  max-width: 40;
  max-height: 40;
  /* margin-right: 0 auto; */
  /* margin-left: 0 auto; */
}
.gra1 {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    240deg,
    rgb(78, 173, 159) 0%,
    /* rgb(88, 172, 231) 35%, */ rgb(64, 175, 159) 55%,
    rgb(193, 228, 223) 100%
  );
}

.bord {
  border-left: 5px solid rgb(5, 143, 131) !important;
}

/* .cbg {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    307deg,
    rgb(255, 255, 255) 0%,
    rgb(142, 208, 255) 35%,
    rgb(234, 240, 253) 100%
  );
} */

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 350px);
  justify-content: space-around;
  grid-gap: 5px;
}

/* .grid::after {
  content: "";
  flex: auto;
} */
.end-text {
  font-size: 18px;
}
</style>